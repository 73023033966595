import React, { useEffect, useState } from "react";
import { db, storage } from "../FB/firebaseConfig.js";
import { collection, getDocs, addDoc, query, where,} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth"; 
import { useNavigate } from "react-router-dom"; 
import ManageCourses from './ManageCourses';
import ManageExams from './ManageExams';
import EnrolledUsers from './EnrolledUsers';
import CourseVideos from './CourseVideos'; // Import the new component
import "./userDashboard.css";
import { ref, listAll, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import AddFiles from './AddFiles'; // Adjust the path if necessary
import AddHomework from './AddHomework'; 
import AddAudio from './AddAudio'; // Import the new component
const UserDashboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [specificUserId, setSpecificUserId] = useState("");
  const [courseName, setCourseName] = useState("");
  const [liveVideoUrl, setLiveVideoUrl] = useState("");
  const [liveVideoFile, setLiveVideoFile] = useState(null);
  const [courses, setCourses] = useState([]);
  const [enrolledUsers, setEnrolledUsers] = useState([]);
  const [userPhone, setUserPhone] = useState("");
  const [grades, setGrades] = useState([]);
  const [activeSection, setActiveSection] = useState("users");
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userCourses,] = useState([]); // State for user courses
  const [selectedCourse, setSelectedCourse] = useState(""); // State for selected course
  const auth = getAuth();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUsers = async () => {
      const userCollection = collection(db, "users");
      const userSnapshot = await getDocs(userCollection);
      const userData = userSnapshot.docs.map(doc => ({
        id: doc.id,
        uid: doc.data().uid,
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        phone: doc.data().phone,
        email: doc.data().email,
      }));
      setUsers(userData);
    };
    fetchUsers();
    const fetchCourses = async () => {
      try {
        const storageRef = ref(storage, 'courses/');
        const courseList = await listAll(storageRef);

        const courseData = await Promise.all(
          courseList.prefixes.map(async (courseRef) => {
            const videosRef = ref(storage, `courses/${courseRef.name}/videos`);
            const videos = await listAll(videosRef);
            const videoUrls = await Promise.all(
              videos.items.map(video => getDownloadURL(video))
            );

            return {
              name: courseRef.name,
              videos: videoUrls,
            };
          })
        );

        setCourses(courseData);
      } catch (error) {
        console.error('Error fetching courses:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
    fetchCourses();
  }, []);



  const fetchEnrolledUsers = async (courseName) => {
    const enrollmentsRef = collection(db, "enrollments");
    const q = query(enrollmentsRef, where("courseName", "==", courseName));
    const enrollmentSnapshot = await getDocs(q);
    const enrolledData = enrollmentSnapshot.docs.map(doc => {
      const userId = doc.data().userId;
      const user = users.find(user => user.uid === userId);
      return {
        userId,
        userName: user ? `${user.firstName} ${user.lastName}` : "Unknown User",
        email: user ? user.email : "N/A",
        phone: user ? user.phone : "N/A",
      };
    });

    setEnrolledUsers(enrolledData);
  };

  const fetchGrades = async (courseName) => {
    const gradesRef = collection(db, "userGrades");
    const q = query(gradesRef, where("courseName", "==", courseName));
    const gradesSnapshot = await getDocs(q);
    const gradesData = gradesSnapshot.docs.map(doc => doc.data());

    setGrades(gradesData);
  };

  const handleCourseChange = (e) => {
    const selectedCourse = e.target.value;
    setCourseName(selectedCourse);
    if (selectedCourse) {
      fetchEnrolledUsers(selectedCourse);
      fetchGrades(selectedCourse);
      setSpecificUserId("");
    } else {
      setEnrolledUsers([]);
      setGrades([]);
      setSpecificUserId("");
    }
  };



  const handleLiveVideoSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setIsSubmitting(true);

    const liveVideoRef = collection(db, "liveVideos");

    try {
      const userIdToPost = specificUserId || selectedUsers[0];
      if (!userIdToPost) {
        setSuccessMessage("Please select a user to post the live video.");
        return;
      }

      if (liveVideoFile) {
        const videoRefPath = ref(storage, `liveVideos/${liveVideoFile.name}`);
        await uploadBytes(videoRefPath, liveVideoFile);
        const uploadedLiveVideoUrl = await getDownloadURL(videoRefPath);

        await addDoc(liveVideoRef, {
          userId: userIdToPost,
          liveVideoUrl: uploadedLiveVideoUrl,
          courseName,
          phone: userPhone,
        });
      } else if (liveVideoUrl) {
        await addDoc(liveVideoRef, {
          userId: userIdToPost,
          liveVideoUrl,
          courseName,
          phone: userPhone,
        });
      }

      setLiveVideoUrl("");
      setLiveVideoFile(null);
      setUserPhone("");
      setSelectedUsers([]);
      setSuccessMessage("Live Video posted successfully!");
    } catch (error) {
      console.error("Error posting live video:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

 

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/"); // Redirect to home page after logout
      console.log("User signed out successfully");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  if (loading) {
    return <div className="loading">Loading courses...</div>;
  }

  return (
    <div className="dashboard">
      <aside className="sidebar">
        <h2>Admin Menu</h2>
        <ul>
        <li onClick={() => setActiveSection("addAudio")}>إضافة ملف صوتي</li> {/* New Item */}

          <li onClick={() => setActiveSection("manageCourses")}>اضافة فيديوهات للدورات</li>
          <li onClick={() => setActiveSection("manageExams")}>انشاء اختبار</li>
          <li onClick={() => setActiveSection("addFiles")}>اضافة ملفات</li>
          <li onClick={() => setActiveSection("homework")}>اضافة واجب</li>
          <li onClick={() => setActiveSection("enrolledUsers")}>صلاحيات الدورات للمستخدمين</li>
          <li onClick={() => setActiveSection("videos")}>اظهار و حذف  فيديوهات الدورات</li> {/* For managing videos */}
          <li onClick={() => setActiveSection("showGrades")}>درجات الاختبارات</li>
          <li onClick={() => setActiveSection("liveVideos")}>اضافة البث المياشر</li>
        </ul>
        <button onClick={handleLogout} className="btn-logout">Logout</button>
      </aside>
      <main className="main-content">
      {activeSection === "addAudio" && <AddAudio />} {/* Render the new component */}

      {activeSection === "addFiles" && <AddFiles />} {/* Render the AddFiles component */}
      {activeSection === "homework" && <AddHomework />} {/* Render the AddHomework component */}
        {activeSection === "liveVideos" && (
          <div>
            <h2>Manage Live Videos</h2>
            <form onSubmit={handleLiveVideoSubmit} className="live-video-form">
              <div className="form-group">
                <label htmlFor="courseSelect">Select Course</label>
                <select 
                  id="courseSelect"
                  value={courseName} 
                  onChange={handleCourseChange} 
                  required 
                  className="styled-select"
                >
                  <option value="">Select Course</option>
                  {courses.map(course => (
                    <option key={course.name} value={course.name}>{course.name}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="liveVideoUrl">Live Video URL</label>
                <input 
                  type="text" 
                  id="liveVideoUrl" 
                  placeholder="Enter Live Video URL" 
                  value={liveVideoUrl} 
                  onChange={(e) => setLiveVideoUrl(e.target.value)} 
                  className="styled-input"
                />
              </div>
              <div className="form-group">
                <label htmlFor="liveVideoFile">Upload Live Video</label>
                <input 
                  type="file" 
                  id="liveVideoFile" 
                  onChange={(e) => setLiveVideoFile(e.target.files[0])} 
                  className="styled-file-input"
                />
              </div>
              <div className="form-group">
                <label>Select User to Post Live Video</label>
                <select 
                  value={specificUserId} 
                  onChange={(e) => setSpecificUserId(e.target.value)} 
                  className="styled-select"
                >
                  <option value="">Select User</option>
                  {enrolledUsers.map(user => (
                    <option key={user.userId} value={user.userId}>
                      {user.userName} ({user.email})
                    </option>
                  ))}
                </select>
              </div>
              <button type="submit" className="btn-submit" disabled={isSubmitting}>
                {isSubmitting ? "Posting..." : "Post Live Video"}
              </button>
            </form>
            {isSubmitting && <div className="loading-message">Posting your live video, please wait...</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            {/* Display Enrolled Users */}
            {courseName && enrolledUsers.length > 0 && (
              <div>
                <h3>Enrolled Users for {courseName}</h3>
                <table className="enrolled-users-table">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {enrolledUsers.map((user, index) => (
                      <tr key={index}>
                        <td>{user.userName.split(" ")[0]}</td>
                        <td>{user.userName.split(" ")[1]}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {activeSection === "manageCourses" && (
          <div>
            <h2>Manage Courses</h2>
            <div className="form-group">
              <label htmlFor="courseSelect">Select Course to Upload Videos/Documents</label>
              <select 
                id="courseSelect" 
                value={courseName} 
                onChange={handleCourseChange} 
                required 
                className="styled-select"
              >
                <option value="">Select Course</option>
                {courses.map(course => (
                  <option key={course.name} value={course.name}>{course.name}</option>
                ))}
              </select>
            </div>
            {courseName && <ManageCourses courseName={courseName} />}
          </div>
        )}
        {activeSection === "manageExams" && (
          <div>
            <h2>Manage Exams</h2>
            <ManageExams courses={courses} />
          </div>
        )}
        {activeSection === "showGrades" && (
          <div>
            <h2>Show Grades</h2>
            <div className="form-group">
              <label htmlFor="gradeCourseSelect">Select Course</label>
              <select 
                id="gradeCourseSelect"
                value={courseName} 
                onChange={handleCourseChange} 
                required 
                className="styled-select"
              >
                <option value="">Select Course</option>
                {courses.map(course => (
                  <option key={course.name} value={course.name}>{course.name}</option>
                ))}
              </select>
            </div>
            {grades.length > 0 ? (
              <table className="grades-table">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Course Name</th>
                    <th>Exam Title</th>
                    <th>Grade</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {grades.map((grade, index) => (
                    <tr key={index}>
                      <td>{grade.firstName}</td>
                      <td>{grade.lastName}</td>
                      <td>{grade.email}</td>
                      <td>{grade.courseName}</td>
                      <td>{grade.examTitle}</td>
                      <td>{grade.grade}</td>
                      <td>{new Date(grade.timestamp.seconds * 1000).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No grades available for this course.</p>
            )}
          </div>
        )}
        {activeSection === "enrolledUsers" && (
          <EnrolledUsers enrolledUsers={enrolledUsers} />
        )}

        {activeSection === "videos" && (
          <CourseVideos 
            userCourses={userCourses} 
            setSelectedCourse={setSelectedCourse} 
            selectedCourse={selectedCourse} 
          />
          
        )}
      </main>
    </div>
  );
};
export default UserDashboard;