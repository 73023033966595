import React, { useState, useEffect } from 'react';
import { storage } from '../FB/firebaseConfig'; 
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject } from 'firebase/storage';
import './addAudio.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../FB/firebaseConfig'; // Adjust the import based on your Firebase config

const AddAudio = () => {
  const [audioFile, setAudioFile] = useState(null);
  const [audioName, setAudioName] = useState(''); // State for audio name
  const [audioList, setAudioList] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    const coursesCollection = collection(db, "courses"); // Adjust the collection name if necessary
    const courseSnapshot = await getDocs(coursesCollection);
    const courseData = courseSnapshot.docs.map(doc => ({ id: doc.id, name: doc.data().name }));
    setCourses(courseData);
  };

  const fetchAudioFiles = async (courseName) => {
    const audioRef = ref(storage, `courses/${courseName}/`);
    const audioListSnapshot = await listAll(audioRef);
    const urls = await Promise.all(
      audioListSnapshot.items.map(async (item) => {
        const url = await getDownloadURL(item);
        return { name: item.name, url };
      })
    );
    setAudioList(urls);
  };

  const handleCourseChange = (e) => {
    const courseName = e.target.value;
    setSelectedCourse(courseName);
    fetchAudioFiles(courseName); // Fetch audio files for the selected course
  };

  const handleFileChange = (e) => {
    setAudioFile(e.target.files[0]);
  };

  const handleNameChange = (e) => {
    setAudioName(e.target.value); // Update audio name state
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setSuccessMessage("");
    setIsSubmitting(true);

    if (!audioFile) {
      setSuccessMessage("Please select an audio file.");
      setIsSubmitting(false);
      return;
    }

    const audioRef = ref(storage, `courses/${selectedCourse}/${audioName || audioFile.name}`); // Use provided name or file name

    try {
      await uploadBytes(audioRef, audioFile);
      setSuccessMessage("Audio uploaded successfully!");
      fetchAudioFiles(selectedCourse); // Refresh the list after upload
      setAudioName(''); // Clear the audio name input
    } catch (error) {
      console.error("Error uploading audio:", error);
      setSuccessMessage("Error uploading audio.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (audioName) => {
    const audioRef = ref(storage, `courses/${selectedCourse}/${audioName}`);
    try {
      await deleteObject(audioRef);
      setSuccessMessage("Audio deleted successfully!");
      fetchAudioFiles(selectedCourse); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting audio:", error);
      setSuccessMessage("Error deleting audio.");
    }
  };

  return (
    <div className="add-audio-container">
      <h2>Add Audio File</h2>
      <select value={selectedCourse} onChange={handleCourseChange} required>
        <option value="">Select Course</option>
        {courses.map(course => (
          <option key={course.id} value={course.name}>{course.name}</option>
        ))}
      </select>
      <form onSubmit={handleUpload}>
        <input type="text" value={audioName} onChange={handleNameChange} placeholder="Enter audio name" required />
        <input type="file" accept="audio/*" onChange={handleFileChange} required />
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Uploading..." : "Upload Audio"}
        </button>
      </form>
      {successMessage && <div>{successMessage}</div>}
      
      <h3>Uploaded Audio Files for {selectedCourse}</h3>
      <ul>
        {audioList.length > 0 ? (
          audioList.map((audio) => (
            <li key={audio.name}>
              <audio controls>
                <source src={audio.url} type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>
              <button onClick={() => handleDelete(audio.name)}>Delete</button>
            </li>
          ))
        ) : (
          <li>No audio files uploaded for this course.</li>
        )}
      </ul>
    </div>
  );
};

export default AddAudio;